import { Typography, WithStyles } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect } from "react";

import { Multiselect } from "../../components/Multiselect";
import { Table } from "../../components/Table";
import {
  DropoutRawResponse,
  DropoutRequest,
  DropoutResponse,
  Policy
} from "./models";
import { DispatchProps, StateProps } from "./redux";
import { DropoutsClassKey } from "./styles";

export interface DropoutsProps {}

interface InsurerState {
  id: string;
  name: string;
}

export interface InsurersState {
  data: InsurerState[];
}

interface ColumnsState {
  columns: Column<DropoutResponse>[];
}

interface LookupItem {
  [id: number]: string;
}

export interface DropoutsInnerProps
  extends DropoutsProps,
    StateProps,
    DispatchProps,
    WithStyles<DropoutsClassKey> {}

export const DropoutsComponent: React.FC<DropoutsInnerProps> = ({
  dropouts,
  addDropout,
  deleteDropout,
  editDropout,
  getDropouts,
  policyTypes,
  getPolicyTypes,
  isLoading,
  classes
}) => {
  useEffect(() => {
    getDropouts();
    getPolicyTypes();
  }, [getDropouts, getPolicyTypes]);

  const policyTypesLookup: LookupItem = policyTypes.reduce(
    (acc, e) => ({
      [e.id]: e.name,
      ...acc
    }),
    {}
  );

  const idToPolicy = (id: number): Policy => ({
    id,
    name: policyTypesLookup[id]
  });

  const columnsState: ColumnsState = {
    columns: [
      {
        field: "id",
        title: "Id",
        editable: "never",
        type: "numeric"
      },
      { field: "uitvalreden", title: "Dropout reason" },
      { field: "argument", title: "Argument" },
      { field: "soort", title: "Type" },
      { field: "in_gebruik", title: "In use" },
      { field: "taak", title: "Task" },
      {
        field: "policy_types",
        title: "Policy Types",
        lookup: policyTypesLookup,

        editComponent: props => {
          return (
            <Multiselect
              items={policyTypesLookup}
              value={props.value || []}
              onChange={props.onChange}
              name="policy-types-filter"
              label="Policy Types"
            />
          );
        },

        render: (rowData: DropoutResponse) => {
          if (policyTypesLookup) {
            return rowData.policy_types.map(id => (
              <Typography key={id} gutterBottom variant="inherit" component="p">
                {policyTypesLookup[id]}
              </Typography>
            ));
          }
        },

        customFilterAndSearch: (terms: string[], rowData: DropoutResponse) => {
          if (terms.length > 0) {
            const ids = terms.map(term => parseInt(term));
            const userShouldRemain = rowData.policy_types.some((id: number) =>
              ids.includes(id)
            );
            return userShouldRemain;
          }

          return true;
        }
      }
    ]
  };

  const handleAddDropout = (newData: DropoutResponse) => {
    const policy_types: Policy[] = newData.policy_types.map(idToPolicy);
    const dropout: DropoutRequest = {
      ...newData,
      policy_types
    };
    return addDropout(dropout);
  };

  const handleDeleteDropout = (oldData: DropoutResponse) =>
    deleteDropout(oldData.id);

  const handleEditDropout = (
    newData: DropoutResponse,
    oldData: DropoutResponse
  ) => {
    const policy_types: Policy[] = newData.policy_types.map(idToPolicy);
    const dropout: DropoutRawResponse = {
      ...newData,
      policy_types
    };
    return editDropout(dropout);
  };

  return (
    <>
      <Typography
        className={classes.pageTitle}
        gutterBottom
        align="center"
        variant="h4"
        component="h5"
      >
        Dropouts
      </Typography>
      <Table
        hasEdit={true}
        hasDelete={true}
        handleAddRow={handleAddDropout}
        handleEditRow={handleEditDropout}
        handleDeleteRow={handleDeleteDropout}
        dataTable={dropouts}
        columnsData={columnsState.columns}
        isLoading={isLoading}
      />
    </>
  );
};
