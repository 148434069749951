import MaterialTable, { Column } from "material-table";
import React from "react";

import { tableIcons } from "../tableIcons";
interface TableProps {
  columnsData: Column<any>[];
  dataTable: Array<object>;
  hasEdit: boolean;
  hasDelete: boolean;
  hasExportButton?: boolean;
  isLoading?: boolean;
  handleAddRow?: (element: any) => Promise<any>;
  handleEditRow?: (element: any, oldData: any) => Promise<any>;
  handleDeleteRow?: (element: any) => Promise<any>;
}

interface TableInnerProps extends TableProps {}

export const TableComponent: React.FC<TableInnerProps> = ({
  columnsData,
  dataTable,
  handleAddRow,
  handleEditRow,
  handleDeleteRow,
  hasEdit,
  hasDelete,
  hasExportButton,
  isLoading
}) => {
  return (
    <MaterialTable
      title=""
      columns={columnsData}
      options={{
        actionsColumnIndex: -1,
        filtering: true,
        sorting: false,
        search: false,
        selection: false,
        draggable: false,
        paging: true,
        exportButton: hasExportButton,
        exportAllData: hasExportButton,
        pageSize: 20,
        addRowPosition: "first"
      }}
      icons={tableIcons}
      isLoading={isLoading}
      data={dataTable.map(o => ({ ...o }))} // unfreeze objects
      editable={{
        isEditable: rowData => hasEdit,
        isDeletable: rowData => hasDelete,
        onRowAdd: handleAddRow,
        onRowUpdate: handleEditRow,
        onRowDelete: handleDeleteRow
      }}
    />
  );
};
