import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { protectedSelector } from "../../components/ProtectedComponent/selectors";
import { showSuccessAction } from "../../components/Toast/toastSlice";
import { loginAction, requestPublicKeyAction } from "../../data/authenticate";
import { loginSelector } from "./selectors";
import useStyles from "./styles";

export interface LoginProps {}

interface LoginState {
  username: string;
  password: string;
}

interface HistoryState {
  from: string;
}

const hasHistory = (locationState: any): locationState is HistoryState =>
  locationState && locationState.from !== undefined;

export const LoginComponent: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const { isAuthenticated } = useSelector(loginSelector);
  const { token } = useSelector(protectedSelector);
  const dispatch = useDispatch();

  const [credentialsFormData, setcredentialsFormData] =
    React.useState<LoginState>({
      username: "",
      password: ""
    });

  const { state } = useLocation();

  const from = hasHistory(state) ? state.from : "/";

  const getEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcredentialsFormData({
      ...credentialsFormData,
      username: event.target.value
    });
  };

  const getPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcredentialsFormData({
      ...credentialsFormData,
      password: event.target.value
    });
  };

  const handleSubmit = () => {
    dispatch(
      loginAction(credentialsFormData.username, credentialsFormData.password)
    );
    dispatch(requestPublicKeyAction());
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(showSuccessAction("You have logged in successfully"));
    }
  }, [dispatch, isAuthenticated]);

  if (isAuthenticated && token !== undefined) {
    return <Redirect to={from} />;
  }

  return (
    <Container maxWidth="xs">
      <Box my={4}>
        <Paper elevation={1}>
          <form
            className={classes.form}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Typography align="center" component="h2" variant="h4">
              Login
            </Typography>
            <TextField
              type="email"
              label="Email"
              value={credentialsFormData.username}
              fullWidth={true}
              onChange={getEmail}
            />
            <TextField
              type="password"
              label="Password"
              value={credentialsFormData.password}
              fullWidth={true}
              onChange={getPassword}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={handleSubmit}
            >
              Login
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};
